var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return _c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Agregar Colaboradores")])],1),_c('v-card-text',[_c('v-form',[_c('ValidationProvider',{attrs:{"name":"Numero Documento","rules":"required|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"counter":8,"error-messages":errors,"success":valid,"label":"Numero Documento","required":""},model:{value:(_vm.form.numeroDocumento),callback:function ($$v) {_vm.$set(_vm.form, "numeroDocumento", $$v)},expression:"form.numeroDocumento"}})}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"success":valid,"label":"E-mail","required":""},model:{value:(_vm.form.correo),callback:function ($$v) {_vm.$set(_vm.form, "correo", $$v)},expression:"form.correo"}})}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password"}},[_c('v-text-field',{attrs:{"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"name":"input-10-1","label":"Password"},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('ValidationProvider',{attrs:{"name":"Nombres","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Nombres","required":""},model:{value:(_vm.form.nombres),callback:function ($$v) {_vm.$set(_vm.form, "nombres", $$v)},expression:"form.nombres"}})}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Apellidos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Apellidos","required":""},model:{value:(_vm.form.apellidos),callback:function ($$v) {_vm.$set(_vm.form, "apellidos", $$v)},expression:"form.apellidos"}})}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Rol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-select',{attrs:{"items":_vm.roles,"item-text":"description","item-value":"id","error-messages":errors,"success":valid,"label":"Rol","required":""},model:{value:(_vm.form.idRol),callback:function ($$v) {_vm.$set(_vm.form, "idRol", $$v)},expression:"form.idRol"}})}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"telefono","rules":"required|max:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return _c('v-text-field',{attrs:{"counter":9,"error-messages":errors,"success":valid,"label":"Numero Telefonico","required":""},model:{value:(_vm.form.telefono),callback:function ($$v) {_vm.$set(_vm.form, "telefono", $$v)},expression:"form.telefono"}})}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.disableRegister},on:{"click":_vm.submit}},[_vm._v("Registrar")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"yellow","disabled":_vm.disableUpdate,"dark":""},on:{"click":_vm.update}},[_vm._v("Actualizar")])],1)],1)}}])})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"multi-sort":"","loading":_vm.loadingCollaborators,"search":_vm.search,"headers":_vm.headers,"items":_vm.listCollaborators,"items-per-page":20},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.editDeleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.editDeleteItem(Object.assign({}, item, {isDelete: true}))}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Advertencia ")]),_c('v-card-text',{staticClass:"lg-4"},[_vm._v(" Ya tenemos almacenado al colaborador en nuestra base de datos. Cualquier cambio que se quiera hacer el usuario lo puede hacer editandolo ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Aceptar ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"auto"},model:{value:(_vm.dialogResponseEdit),callback:function ($$v) {_vm.dialogResponseEdit=$$v},expression:"dialogResponseEdit"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Anuncio ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.BodyEdit)+" ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){_vm.dialogResponseEdit = false}}},[_vm._v("Aceptar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }